<script lang="ts" setup>
import { ref, watch } from "vue";

const props = defineProps<{
  product: Record<string, any>;
  palette: string | undefined;
}>();
const { productPageUrl, getAssetUrl } = useHelpers();
const scrollElement = ref(null);

const productImageRef = ref("");
const paletteDataRef = ref();
const currentPaletteRef = ref(); // this stores the currently viewed palette (dot)
const currentColorRef = ref(); // this stores the currently viewed color
let currentPaletteColorIndex = 0; // this stores the currently viewed color of the current dot(palette), so we can cycle over them
const priceFieldToUseRef = ref("calculated_base_prices");
const { status: authStatus, data: authData } = useAuth();

// -----------------------
// vue events
// -----------------------
onMounted(() => {
  calculateRefs();
});

watch(
  () => props.palette,
  (newValue /*, oldValu */) => {
    currentPaletteColorIndex = 0;
    currentPaletteRef.value = newValue?.toString() ? newValue.toString() : "";
    // console.log(`Setting palette for product "${props.product.name}" changed:`, currentPaletteRef.value);
  },
);

watch(
  () => currentPaletteRef.value,
  () => {
    currentPaletteColorIndex = 0;
    showPaletteImage();
    // console.log(`Applying Palette for product "${props.product.name}" changed:`, currentPaletteRef.value);
  },
);

// -----------------------
// helper methods
// -----------------------
const calculateRefs = () => {
  // calculate the paletteDataRef
  const colors = new Map<string, string[]>();
  for (const product of props.product.products) {
    if (product.product.product_paletta_property && product.product.stock > 0) {
      if (!colors.get(product.product.product_paletta_property))
        colors.set(product.product.product_paletta_property, [
          product.product.product_szin_property,
        ]);
      if (
        !colors
          .get(product.product.product_paletta_property)
          ?.includes(product.product.product_szin_property)
      )
        colors
          .get(product.product.product_paletta_property)
          ?.push(product.product.product_szin_property);
    }
  }
  // console.log('paletteDataRef', colors);
  paletteDataRef.value = colors;

  // default image
  // console.log('props.palette: ', props.palette);
  if (props.palette) {
    currentPaletteRef.value = props.palette;
    showPaletteImage();
  } else {
    showDefaultImage();
  }

  // TV prices
  if (authStatus.value === "authenticated") {
    if (
      Array.isArray(authData?.value?.usergroups) &&
      authData?.value?.usergroups?.includes(1)
    ) {
      priceFieldToUseRef.value = "calculated_pricelist_1_prices";
    }
  }
};

const showDefaultImage = () => {
  if (Array.isArray(props.product.products)) {
    for (const variant of props.product.products) {
      if (
        variant.product.stock > 0 &&
        Array.isArray(variant.images) &&
        variant.images.length
      ) {
        currentColorRef.value = variant.product.product_szin_property;
        productImageRef.value = getAssetUrl(variant.images[0]);
      }
    }
  }
};

const paletteDotClasses = (color: string) => {
  const classes: string[] = [
    "rounded-full",
    "mr-1",
    "inline-block",
    "h-[16px]",
    "w-[16px]",
    "palette-dot",
    "text-center",
    "cursor-pointer",
  ];

  classes.push("bg-palette-" + color);

  return classes.join(" ");
};

const paletteColors = (products: any) => {
  const colors = new Map<string, string[]>();
  for (const product of products) {
    if (product.product.product_paletta_property && product.product.stock > 0) {
      if (!colors.get(product.product.product_paletta_property))
        colors.set(product.product.product_paletta_property, [
          product.product.product_szin_property,
        ]);
      if (
        !colors
          .get(product.product.product_paletta_property)
          ?.includes(product.product.product_szin_property)
      )
        colors
          .get(product.product.product_paletta_property)
          ?.push(product.product.product_szin_property);
    }
  }
  // console.log(Object.fromEntries(colors))
  return Object.fromEntries(colors);
};

const showNextImageForCurrentPalette = () => {
  // console.log('showNextImageForCurrentPalette');

  const paletteData = paletteDataRef.value.get(currentPaletteRef.value);
  // console.log('paletteData', paletteData);

  if (currentPaletteRef.value && paletteData) {
    let currentImageIndex = 0;
    currentPaletteColorIndex++;
    if (currentPaletteColorIndex >= paletteData.length)
      currentPaletteColorIndex = 0;
    if (currentImageIndex >= paletteData.length) currentImageIndex = 0;
    for (const product of props.product.products) {
      if (
        product.product.product_paletta_property === currentPaletteRef.value &&
        product.images.length
      ) {
        if (currentImageIndex === currentPaletteColorIndex) {
          currentColorRef.value = product.product.product_szin_property;
          productImageRef.value = getAssetUrl(product.images[0]);
          return;
        }
        currentImageIndex++;
      }
    }
  }
};

const handlePaletteDotClick = (palette: string | undefined) => {
  if (palette && currentPaletteRef.value !== palette) {
    // need to switch to a new palette
    currentPaletteColorIndex = 0;
    currentPaletteRef.value = palette;
  } else {
    // need to show the next color from the current palette
    showNextImageForCurrentPalette();
  }
};

const showPaletteImage = () => {
  if (!currentPaletteRef.value) {
    showDefaultImage();
    return;
  }

  for (const product of props.product.products) {
    if (
      product.product.product_paletta_property === currentPaletteRef.value &&
      product.images.length
    ) {
      currentColorRef.value = product.product.product_szin_property;
      productImageRef.value = getAssetUrl(product.images[0]);
      return;
    }
  }
  showDefaultImage();
};

const productPageLink = () => {
  return productPageUrl(props.product) + `?color=${currentColorRef.value}`;
};
</script>

<template>
  <div
    ref="scrollElement"
    class="bg-white rounded-30 p-5 flex flex-col justify-between shadow-md"
  >
    <!-- Content at the top of the element -->
    <div>
      <a
        :href="productPageLink()"
        class="block rounded overflow-hidden flex justify-center items-center"
      >
        <NuxtImg
          v-if="productImageRef"
          width="300"
          :alt="props.product.name"
          class="object-center object-contain h-full"
          :src="productImageRef"
          format="webp"
          loading="lazy"
        />
        <div v-else class="text-center text-surface00">
          {{ props.product.sku }}
        </div>
      </a>
      <div class="mt-4">
        <div class="flex mb-5 justify-center h-[16px]">
          <template
            v-for="(colorArray, color, index) in paletteColors(
              props.product.products,
            )"
            :key="color.toString()"
          >
            <div
              v-if="index < 4"
              :class="paletteDotClasses(color.toString())"
              @click="handlePaletteDotClick(color.toString())"
            >
              <!--
              <span
                v-if="colorArray.length > 1"
                class="text-center align-top counter text-xs"
                >{{ colorArray.length }}</span
              >
              -->
            </div>
            <a
              v-if="
                index == 4 &&
                Object.keys(paletteColors(props.product.products)).length > 5
              "
              :href="productPageLink()"
              :class="paletteDotClasses('grey')"
              class="bg-[#D9D9D9] relative"
              ><div
                class="flex items-center justify-center h-[17px] text-black"
              >
                +
              </div></a
            >
            <div
              v-else-if="index == 4"
              :class="paletteDotClasses(color.toString())"
              @click="handlePaletteDotClick(color.toString())"
            >
              <span
                v-if="colorArray.length > 1"
                class="text-center align-top counter text-xs"
                >{{ colorArray.length }}</span
              >
            </div>
          </template>
        </div>
        <h2>
          <template v-if="$slots.name">
            <slot name="name" :url="productPageLink()" />
          </template>

          <div v-else class="text-xs md:text-sm">
            <a :href="productPageLink()" class="text-black unstyled">
              <div class="font-bold font-lora">
                {{ props.product.masterproduct_gyarto_property }}
              </div>
              <div>{{ props.product.name }}</div>
            </a>
          </div>
        </h2>
      </div>
    </div>

    <!-- Content at the bottom of the element -->
    <template v-if="$slots.price">
      <slot name="price" />
    </template>

    <div v-else class="flex items-end">
      <p class="mt-5 text-xs md:text-sm">
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="usePriceFormatter(props.product, 'masterProduct')" />
        <!-- eslint-enable -->
      </p>
    </div>
  </div>
</template>

<style scoped>
.bg-palette-mix {
  background-image: url("/images/mix-color-icon-100x100.webp");
  background-size: cover;
}

.bg-palette-white {
  border: 1px solid #222;
}
</style>
