<script lang="ts" setup>
import type { BackgroundImage } from "~/types/common";
import { useMainStore } from "~/stores/main";

interface CarouselItem {
  image: BackgroundImage;
  mobileImage: BackgroundImage;
  url?: string;
}

interface CarouselConfiguration {
  items: CarouselItem[];
}

interface ImageSize {
  width: number;
  height: number;
}

// -----------------------
// props & emits
// -----------------------
const props = defineProps<{
  options: CarouselConfiguration;
}>();

// -----------------------
// composables
// -----------------------
const mainStore = useMainStore();
const img = useImage();
const { getAssetUrl } = useHelpers();

// -----------------------
// reactive properties
// -----------------------
const container = ref();
const containerWidth = ref<number>(0);
const slides = ref();
const items = ref<CarouselItem[]>([]);
const initialized = ref(false);
const imageSizes = ref({});
const activeSlideIndex = ref<number>(0);
const height = ref<number>(0);

// -----------------------
// computed properties
// -----------------------
const activeSlideWidthRatio = computed(() => {
  if (mainStore.breakpoint === "sm") {
    return 42;
  } else {
    return 80;
  }
});

const activeSlide = computed(() => {
  return items.value[activeSlideIndex.value];
});

// -----------------------
// helper methods
// -----------------------
const getSlideClasses = (item: CarouselItem, index: number) => {
  const classes = ["absolute", "slide"];

  if (index === activeSlideIndex.value - 1) {
    classes.push("previous z-10 opacity-50 cursor-pointer");
  } else if (index === activeSlideIndex.value) {
    if (mainStore.breakpoint === "sm") {
      classes.push("active-mobile");
    } else {
      classes.push("active");
    }

    classes.push("z-50 opacity-100");
  } else if (index === activeSlideIndex.value + 1) {
    classes.push("next z-10 opacity-50 cursor-pointer");
  } else {
    classes.push("hidden");
  }

  if (!classes.includes("cursor-pointer") && item.url) {
    classes.push("cursor-pointer");
  }

  return classes;
};

const nextSlide = () => {
  if (activeSlideIndex.value < items.value.length - 1) {
    activeSlideIndex.value = activeSlideIndex.value + 1;
  }
};

const previousSlide = () => {
  if (activeSlideIndex.value > 0) {
    activeSlideIndex.value = activeSlideIndex.value - 1;
  }
};

const handleSlideClick = (index: number) => {
  if (activeSlideIndex.value !== index) {
    activeSlideIndex.value = index;
  }
};

const updateBaseWidth = () => {
  if (container.value !== null) {
    containerWidth.value = container.value.getBoundingClientRect().width;
  }
};

const getActiveImageDimensions = () => {
  const image =
    mainStore.breakpoint === "sm"
      ? activeSlide.value.mobileImage
      : activeSlide.value.image;
  const imageSize = imageSizes.value[image.url];
  const width = Math.round(
    containerWidth.value * (activeSlideWidthRatio.value / 100),
  );
  const aspectRatio = imageSize.width / imageSize.height;
  const height =
    mainStore.breakpoint === "sm" ? 360 : Math.round(width / aspectRatio);

  return { width, height };
};

const getSlideImageUrl = (index?: number) => {
  if (!index) {
    index = activeSlideIndex.value;
  }

  if (!items.value[index]) {
    return null;
  }

  if (mainStore.breakpoint === "sm") {
    return items.value[index].mobileImage.url;
  } else {
    return items.value[index].image.url;
  }
};

const getSlideImageStyle = (item: CarouselItem, index: number) => {
  let width;
  let imageRatio;
  let height;
  let top;
  let url;
  const backgroundImage =
    mainStore.breakpoint === "sm"
      ? img(getAssetUrl(item.mobileImage.url), { format: "webp" })
      : img(getAssetUrl(item.image.url), { format: "webp" });
  const activeImageDimensions = getActiveImageDimensions();

  if (
    index === activeSlideIndex.value - 1 ||
    index === activeSlideIndex.value + 1
  ) {
    height = activeImageDimensions.height * 0.9;
    url = getSlideImageUrl(index);
    imageRatio = imageSizes.value[url].width / imageSizes.value[url].height;
    width =
      mainStore.breakpoint === "sm"
        ? containerWidth.value * ((100 - activeSlideWidthRatio.value) / 2 / 100)
        : Math.round(height * imageRatio);
    top = Math.round((activeImageDimensions.height - height) / 2);
  } else if (index === activeSlideIndex.value) {
    width = activeImageDimensions.width;
    height = activeImageDimensions.height;
    top = 0;
  }

  return {
    width: `${width}px`,
    height: `${height}px`,
    "background-image": `url(${backgroundImage})`,
    top: `${top}px`,
  };
};

const getImageSize = (src: string): Promise<ImageSize> => {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = function () {
      resolve({
        width: image.width,
        height: image.height,
      });
    };

    image.onerror = () => {
      reject(new Error("Image could not be loaded"));
    };

    image.src = src;
  });
};

// -----------------------
// vue events
// -----------------------
onMounted(async () => {
  updateBaseWidth();

  for (const item of props.options.items) {
    const modifiers = { format: "webp" };
    const imageUrl = img(getAssetUrl(item.image.url), modifiers);
    const mobileImageUrl = img(getAssetUrl(item.mobileImage.url), modifiers);

    try {
      const imageSize = await getImageSize(imageUrl);
      const mobileImageSize = await getImageSize(mobileImageUrl);

      imageSizes.value[item.image.url] = {
        width: imageSize.width,
        height: imageSize.height,
      };

      imageSizes.value[item.mobileImage.url] = {
        width: mobileImageSize.width,
        height: mobileImageSize.height,
      };

      items.value.push(item);
    } catch (e) {}
  }

  initialized.value = true;
});
window.addEventListener("resize", updateBaseWidth);

watch([initialized, activeSlideIndex, containerWidth], () => {
  if (!initialized.value) {
    return;
  }

  if (
    (mainStore.breakpoint !== "sm" &&
      activeSlide.value.image.url in imageSizes.value) ||
    (mainStore.breakpoint === "sm" &&
      activeSlide.value.mobileImage.url in imageSizes.value)
  ) {
    height.value = getActiveImageDimensions().height;
  }
});
</script>

<template>
  <div
    ref="container"
    v-touch:swipe.left="nextSlide"
    v-touch:swipe.right="previousSlide"
  >
    <!-- Carousel items container -->
    <div
      v-if="initialized"
      ref="slides"
      class="relative"
      :style="{ height: `${height}px` }"
    >
      <template v-for="(item, index) in items" :key="index">
        <div
          class="bg-no-repeat bg-cover bg-center"
          :class="getSlideClasses(item, index)"
          :style="getSlideImageStyle(item, index)"
          @click="handleSlideClick(index)"
        >
          <a
            v-if="activeSlideIndex === index && item.url"
            :href="item.url"
            class="block w-full h-full"
          ></a>
        </div>
      </template>
    </div>

    <!-- Navigation, dots -->
    <div v-if="initialized" class="flex mt-[30px] items-center justify-center">
      <span
        v-for="(i, index) in items.length"
        :key="index"
        class="w-[10px] h-[10px] rounded-full cursor-pointer transition-colors duration-200"
        :class="{
          'bg-brand-secondary': activeSlideIndex !== i - 1,
          'bg-black': activeSlideIndex === i - 1,
          'mr-[24px] md:mr-[30px]': i < items.length,
        }"
        @click="activeSlideIndex = i - 1"
      ></span>
    </div>
  </div>
</template>

<style>
.slide {
  transition-duration: 500ms;
  transition-property: z-index, top, left, right, width, height, opacity;
}

.active {
  left: 10%;
}

.active-mobile {
  left: 29%;
}

.next {
  right: 0;
}
</style>
