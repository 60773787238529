<script lang="ts" setup>
import { useMainStore } from "~/stores/main";
import type { BackgroundImage } from "~/types/common";

interface BannerConfiguration {
  size?: "S" | "M" | "L";
  title?: string;
  titleColor?: string;
  subtitle?: string;
  subtitleColor?: string;
  buttonStyle?: string;
  buttonText?: string;
  buttonUrl?: string;
  backgroundImage?: BackgroundImage;
  mobileBackgroundImage?: BackgroundImage;
}

// -----------------------
// props & emits
// -----------------------
const props = defineProps<{
  options: BannerConfiguration;
}>();

// -----------------------
// composables
// -----------------------
const mainStore = useMainStore();
const { getAssetUrl } = useHelpers();

// -----------------------
// computed properties
// -----------------------

const backgroundImageVisible = computed(() => {
  return !!(
    props.options.backgroundImage &&
    "url" in props.options.backgroundImage &&
    props.options.backgroundImage.url !== ""
  );
});

const mobileBackgroundImageVisible = computed(() => {
  return !!(
    props.options.mobileBackgroundImage &&
    "url" in props.options.mobileBackgroundImage &&
    props.options.mobileBackgroundImage.url !== ""
  );
});

const backgroundImageUrl = computed(() => {
  if (props.options.backgroundImage && "url" in props.options.backgroundImage) {
    return getAssetUrl(props.options.backgroundImage.url);
  } else {
    return "";
  }
});

const mobileBackgroundImageUrl = computed(() => {
  if (
    props.options.mobileBackgroundImage &&
    "url" in props.options.mobileBackgroundImage
  ) {
    return getAssetUrl(props.options.mobileBackgroundImage.url);
  } else {
    return "";
  }
});

const backgroundImageAspectRatio = computed(() => {
  if (
    props.options.backgroundImage &&
    "width" in props.options.backgroundImage &&
    "height" in props.options.backgroundImage
  ) {
    return `${props.options.backgroundImage.width} / ${props.options.backgroundImage.height}`;
  } else {
    return null;
  }
});

const mobileBackgroundImageAspectRatio = computed(() => {
  if (
    props.options.mobileBackgroundImage &&
    "width" in props.options.mobileBackgroundImage &&
    "height" in props.options.mobileBackgroundImage
  ) {
    return `${props.options.mobileBackgroundImage.width} / ${props.options.mobileBackgroundImage.height}`;
  } else {
    return null;
  }
});

const containerStyle = computed(() => {
  if (mainStore.breakpoint === "sm" && mobileBackgroundImageAspectRatio.value) {
    return {
      "aspect-ratio": mobileBackgroundImageAspectRatio.value,
    };
  } else if (
    mainStore.breakpoint !== "sm" &&
    backgroundImageAspectRatio.value
  ) {
    return {
      "aspect-ratio": backgroundImageAspectRatio.value,
    };
  } else {
    return "";
  }
});

const titleVisible = computed(() => {
  return props.options.title && props.options.title !== "";
});

const subtitleVisible = computed(() => {
  if (!props.options.subtitle || props.options.subtitle === "") {
    return false;
  }

  if (props.options.size === "S") {
    return false;
  }

  return true;
});

const buttonVisible = computed(() => {
  return (
    props.options.size !== "S" &&
    props.options.buttonText &&
    props.options.buttonText !== "" &&
    props.options.buttonUrl &&
    props.options.buttonUrl !== ""
  );
});
</script>

<template>
  <div class="relative" :style="containerStyle">
    <NuxtImg
      v-if="backgroundImageVisible"
      :src="backgroundImageUrl"
      :alt="options.title ?? ''"
      class="w-full h-auto hidden md:block"
      format="webp"
      loading="lazy"
    />
    <NuxtImg
      v-if="mobileBackgroundImageVisible"
      :src="mobileBackgroundImageUrl"
      :alt="options.title ?? ''"
      class="w-full h-auto block md:hidden"
      format="webp"
      loading="lazy"
    />
    <div
      class="absolute top-0 left-0 w-full h-full flex flex-col md:items-center justify-center"
    >
      <!-- Title -->
      <h1
        v-if="titleVisible"
        :style="{ color: options.titleColor }"
        class="text-center font-bold font-lora text-white !mt-0 drop-shadow !text-30 md:!text-36 lg:!text-48 xl:!text-60"
      >
        {{ options.title }}
      </h1>

      <!-- Subtitle -->
      <h2
        v-if="subtitleVisible"
        :style="{ color: options.subtitleColor }"
        class="font-bold font-lora text-white text-center drop-shadow !text-20 md:!text-[24px] lg:!text-30 xl:!text-[36px] !mt-[20px] px-[16px] md:px-0"
      >
        {{ options.subtitle }}
      </h2>

      <!-- Button -->
      <a
        v-if="buttonVisible"
        :href="options.buttonUrl"
        class="flex items-center justify-center self-center md:font-bold rounded-full font-bold h-[36px] md:h-[44px] text-[14px] md:text-[16px] px-[30px] md:px-[60px] mt-[30px]"
        :class="{
          'bg-brand-headline !text-brand-surface':
            options.buttonStyle === 'primary',
          'bg-brand-secondary !text-brand-headline':
            options.buttonStyle === 'secondary',
        }"
        >{{ options.buttonText }}</a
      >
    </div>
  </div>
</template>
