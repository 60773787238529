<template>
  <!-- TNF Footer -->
  <footer
    class="mt-[50px] pt-[38px] px-[34px] pb-[11px] bg-brand-surface rounded-t-10"
  >
    <div class="flex flex-col lg:flex-row text-center lg:text-left">
      <div class="font-light text-12 lg:mr-[50px]">
        <div class="font-lora font-medium text-20">
          {{ $t("footer.information") }}
        </div>
        <div
          class="w-[80px] h-[1px] bg-black mt-[14px] mb-[5px] mx-auto lg:mx-0"
        ></div>
        <NuxtLink to="/rolunk" class="block">{{
          $t("footer.about_us")
        }}</NuxtLink>
        <NuxtLink to="/kapcsolat" class="block">{{
          $t("footer.contact")
        }}</NuxtLink>
        <NuxtLink to="/uzletszabalyzat" class="block">{{
          $t("footer.terms_and_conditions")
        }}</NuxtLink>
        <NuxtLink to="/adatkezelesi-tajekoztato" class="block">{{
          $t("footer.privacy_policy")
        }}</NuxtLink>
      </div>
      <div class="font-light text-12 mt-[38px] lg:mt-0">
        <div class="font-lora font-medium text-20">
          {{ $t("footer.buying_guide") }}
        </div>
        <div
          class="w-[80px] h-[1px] bg-black mt-[14px] mb-[5px] mx-auto lg:mx-0"
        ></div>
        <NuxtLink to="/technologiak" class="block">{{
          $t("footer.technologies")
        }}</NuxtLink>
        <NuxtLink to="/merettablazat" class="block">{{
          $t("footer.size_guide")
        }}</NuxtLink>
      </div>
      <div class="flex grow items-center justify-center mt-[38px] lg:mt-0">
        <NuxtLink to="/kapcsolat">
          <BaseButton type="primary" class="w-[236px] h-50">{{
            $t("footer.store_finder")
          }}</BaseButton>
        </NuxtLink>
      </div>
      <div class="mt-[38px] lg:mt-0">
        <NuxtLink to="/" aria-label="The North Face webáruház nyitólap">
          <img
            :src="`/images/theme/tnf/logo_desktop_240829.svg`"
            class="w-[201px] h-[92px] lg:mr-[61px] mx-auto"
            loading="lazy"
            alt="The North Face webáruház"
          />
        </NuxtLink>
      </div>
    </div>

    <div
      class="flex flex-col lg:flex-row justify-between mt-[38px] lg:mt-[43px] text-10"
    >
      <div>
        <ul class="p-0 m-0 flex flex-col lg:flex-row">
          <li class="lg:after:content-['|'] lg:after:mx-[4px]">
            <NuxtLink to="/uzletszabalyzat">{{
              $t("footer.terms_and_conditions")
            }}</NuxtLink>
          </li>
          <li class="lg:after:content-['|'] lg:after:mx-[4px]">
            <NuxtLink to="/adatkezelesi-tajekoztato">{{
              $t("footer.privacy_policy")
            }}</NuxtLink>
          </li>
          <li>
            <NuxtLink to="/cookie-tajekoztato">{{
              $t("footer.cookie_policy")
            }}</NuxtLink>
          </li>
        </ul>
      </div>
      <div class="mt-3 lg:mt-0">
        {{ $t("footer.copyright", { year: new Date().getFullYear() }) }}
      </div>
    </div>
  </footer>
</template>

<style scoped>
.topShadow {
  -webkit-box-shadow: 0px -4px 9px rgba(50, 50, 50, 0.5);
  -moz-box-shadow: 0px -4px 9px rgba(50, 50, 50, 0.5);
  box-shadow: 0px -4px 9px rgba(50, 50, 50, 0.5);
}

.theme-group-tnf footer {
  box-shadow: -4px -4px 4px 0 rgba(0, 0, 0, 0.25);
}

footer .szabadban-szabadon {
  border-top: 1px solid #eae8e2;
  border-bottom: 1px solid #eae8e2;
  color: #eae8e2;
}

footer .separator {
  width: 80px;
  height: 1px;
  display: inline-block;
  background-color: #eae8e2;
}
</style>
