const priceInCurrency = (price: any) => {
  const config = useRuntimeConfig();

  // Parse the price as a float to support decimal values
  const parsedPrice = parseFloat(price);
  if (isNaN(parsedPrice)) {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.log("Invalid price value");
    }
    return "";
  }

  if (config.public.currency === "HUF") {
    return parsedPrice.toLocaleString(config.public.locale, {
      style: "currency",
      currency: config.public.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else if (["EUR", "RON"].includes(config.public.currency)) {
    return parsedPrice.toLocaleString(config.public.locale, {
      style: "currency",
      currency: config.public.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  } else {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.log("Invalid currency");
    }
    return "";
  }
};

export function usePriceFormatter(price: any, method = "") {
  /*
  console.log(typeof price);
  console.log(method);
  console.log(price);
  */
  if (typeof price === "string") {
    price = parseInt(price);
    if (price) {
      return priceInCurrency(price);
    }
  } else if (typeof price === "number") {
    return priceInCurrency(price);
  } else if (method === "masterProduct") {
    // full product meilisearch object, we need to take accoint the usergroup
    const { status, data } = useAuth();
    // console.log(price);

    if (status.value === "authenticated") {
      if (
        Array.isArray(data?.value?.usergroups) &&
        data?.value?.usergroups?.includes(1)
      ) {
        // 1.a: a felhasználó TV

        // 1.a.i: akciós a termék?
        if (
          price.calculated_base_prices.original_price_min ===
          price.calculated_base_prices.price_min
        ) {
          // 1.a.i.1: nem akciós

          // 1.a.i.1.a: minden variáns ára egyforma?
          if (
            price.calculated_base_prices.original_price_min ===
            price.calculated_base_prices.original_price_max
          ) {
            // 1.a.i.1.a.i: igen, minden variáns ára egyforma
            return (
              '<!-- 1.a.i.1.a.i --><div class="originalPrice whitespace-nowrap">' +
              priceInCurrency(price.calculated_base_prices.original_price_min) +
              '</div><div class="text-xs">' +
              useNuxtApp().$i18n.t("pricelist_1_price") +
              '</div><div class="font-bold">' +
              priceInCurrency(price.calculated_pricelist_1_prices.price_min) +
              "</div>"
            );
          } else {
            // 1.a.i.1.a.ii: nem, a variánsok ára különbözik
            return (
              '<!-- 1.a.i.1.a.ii --><div class="originalPrice whitespace-nowrap">' +
              priceInCurrency(price.calculated_base_prices.original_price_min) +
              " - " +
              priceInCurrency(price.calculated_base_prices.original_price_max) +
              '</div><div class="text-xs">' +
              useNuxtApp().$i18n.t("pricelist_1_price") +
              '</div><div class="font-bold">' +
              priceInCurrency(price.calculated_pricelist_1_prices.price_min) +
              " - " +
              priceInCurrency(price.calculated_pricelist_1_prices.price_max) +
              "</div>"
            );
          }
        } else {
          // 1.a.i.2: a termék akciós

          // 1.a.i.2.a
          // eslint-disable-next-line no-lonely-if
          if (
            price.calculated_base_prices.original_price_min ===
            price.calculated_base_prices.original_price_max
          ) {
            // 1.a.i.2.a.i (Egyféle lista áruk van)

            // 1.a.i.2.a.i.1
            if (
              price.calculated_base_prices.price_min ===
              price.calculated_pricelist_1_prices.price_min
            ) {
              // 1.a.i.2.a.i.1.a (igen)

              // 1.a.i.2.a.i.1.a.i
              if (
                price.calculated_base_prices.price_min ===
                price.calculated_base_prices.price_max
              ) {
                // 1.a.i.2.a.i.1.a.i.1 (tehát minden variánsnak egy ára van)
                return (
                  '<!-- 1.a.i.2.a.i.1.a.i.1 --><div class="originalPrice whitespace-nowrap">' +
                  priceInCurrency(
                    price.calculated_base_prices.original_price_min,
                  ) +
                  '</div><div class="inActionPrice font-bold">' +
                  priceInCurrency(price.calculated_base_prices.price_min) +
                  "</div>"
                );
              } else {
                // 1.a.i.2.a.i.1.a.i.2 (tehát vannak különböző árú variánsok)

                // 1.a.i.2.a.i.1.a.i.2.a
                // eslint-disable-next-line no-lonely-if
                if (
                  price.calculated_base_prices.price_max ===
                  price.calculated_base_prices.original_price_max
                ) {
                  // 1.a.i.2.a.i.1.a.i.2.a.i (van teljes árú variáns)
                  return (
                    '<!-- 1.a.i.2.a.i.1.a.i.2.a.i.1 --><div class="originalPrice whitespace-nowrap">' +
                    priceInCurrency(
                      price.calculated_base_prices.original_price_min,
                    ) +
                    '</div><div class="inActionPrice font-bold">' +
                    priceInCurrency(price.calculated_base_prices.price_min) +
                    " - " +
                    priceInCurrency(
                      price.calculated_pricelist_1_prices.price_max,
                    ) +
                    "</div>"
                  );
                } else {
                  // 1.a.i.2.a.i.1.a.i.2.a.ii (minden variáns akciós)
                  return (
                    '<!-- 1.a.i.2.a.i.1.a.i.2.a.ii.1 --><div class="originalPrice whitespace-nowrap">' +
                    priceInCurrency(
                      price.calculated_base_prices.original_price_min,
                    ) +
                    '</div><div class="inActionPrice font-bold">' +
                    priceInCurrency(price.calculated_base_prices.price_min) +
                    " - " +
                    priceInCurrency(price.calculated_base_prices.price_max) +
                    "</div>"
                  );
                }
              }
            } else {
              // 1.a.i.2.a.i.1.b (5% akció van a termékre)

              // 1.a.i.2.a.i.1.b.i
              // eslint-disable-next-line no-lonely-if
              if (
                price.calculated_base_prices.price_min ===
                price.calculated_base_prices.price_max
              ) {
                // 1.a.i.2.a.i.1.b.i.1
                return (
                  '<!-- 1.a.i.2.a.i.1.b.i.1 --><div class="originalPrice whitespace-nowrap">' +
                  priceInCurrency(
                    price.calculated_base_prices.original_price_min,
                  ) +
                  '</div><div class="text-xs">' +
                  useNuxtApp().$i18n.t("pricelist_1_price") +
                  '</div><div class="font-bold">' +
                  priceInCurrency(
                    price.calculated_pricelist_1_prices.price_min,
                  ) +
                  "</div>"
                );
              } else {
                // 1.a.i.2.a.i.1.b.i.2
                return (
                  '<!-- 1.a.i.2.a.i.1.b.i.2 --><div class="originalPrice whitespace-nowrap">' +
                  priceInCurrency(
                    price.calculated_base_prices.original_price_min,
                  ) +
                  " - " +
                  priceInCurrency(
                    price.calculated_base_prices.original_price_max,
                  ) +
                  '</div><div class="text-xs">' +
                  useNuxtApp().$i18n.t("pricelist_1_price") +
                  '</div><div class="font-bold">' +
                  priceInCurrency(
                    price.calculated_pricelist_1_prices.price_min,
                  ) +
                  " - " +
                  priceInCurrency(
                    price.calculated_pricelist_1_prices.price_max,
                  ) +
                  "</div>"
                );
              }
            }
          } else {
            // 1.a.i.2.a.ii (Különböző lista árak)

            // 1.a.i.2.a.ii.1
            // eslint-disable-next-line no-lonely-if
            if (
              price.calculated_base_prices.price_min ===
              price.calculated_pricelist_1_prices.price_min
            ) {
              // 1.a.i.2.a.ii.1.a

              // 1.a.i.2.a.ii.1.a.i
              if (
                price.calculated_base_prices.price_min ===
                price.calculated_base_prices.price_max
              ) {
                // 1.a.i.2.a.ii.1.a.i.1 (tehát minden variánsnak egy ára van)
                return (
                  '<!-- 1.a.i.2.a.ii.1.a.i.1 --><div class="originalPrice whitespace-nowrap">' +
                  priceInCurrency(
                    price.calculated_base_prices.original_price_min,
                  ) +
                  " - " +
                  priceInCurrency(
                    price.calculated_base_prices.original_price_max,
                  ) +
                  '</div><div class="font-bold">' +
                  priceInCurrency(price.calculated_base_prices.price_min) +
                  "</div>"
                );
              } else {
                // 1.a.i.2.a.ii.1.a.i.2 (tehát vannak különböző árú variánsok)

                // 1.a.i.2.a.ii.1.a.i.2.a
                // eslint-disable-next-line no-lonely-if
                if (
                  price.calculated_base_prices.price_max ===
                  price.calculated_base_prices.original_price_max
                ) {
                  // 1.a.i.2.a.ii.1.a.i.2.a.i (Igaz, van teljes árú variáns)
                  return (
                    '<!-- 1.a.i.2.a.ii.1.a.i.2.a.i.1 --><div class="originalPrice whitespace-nowrap">' +
                    priceInCurrency(
                      price.calculated_base_prices.original_price_min,
                    ) +
                    " - " +
                    priceInCurrency(
                      price.calculated_base_prices.original_price_max,
                    ) +
                    '</div><div class="inActionPrice font-bold">' +
                    priceInCurrency(price.calculated_base_prices.price_min) +
                    " - " +
                    priceInCurrency(
                      price.calculated_pricelist_1_prices.price_max,
                    ) +
                    "</div>"
                  );
                } else {
                  // 1.a.i.2.a.ii.1.a.i.2.a.ii (Hamis, minden variáns akciós)
                  return (
                    '<!-- 1.a.i.2.a.ii.1.a.i.2.a.ii.1 --><div class="originalPrice whitespace-nowrap">' +
                    priceInCurrency(
                      price.calculated_base_prices.original_price_min,
                    ) +
                    " - " +
                    priceInCurrency(
                      price.calculated_base_prices.original_price_max,
                    ) +
                    '</div><div class="font-bold">' +
                    priceInCurrency(price.calculated_base_prices.price_min) +
                    " - " +
                    priceInCurrency(price.calculated_base_prices.price_max) +
                    "</div>"
                  );
                }
              }
            } else {
              // 1.a.i.2.a.ii.1.b (5% akció van a termékre)
              return (
                '<!-- 1.a.i.2.a.ii.1.b.1 --><div class="originalPrice whitespace-nowrap">' +
                priceInCurrency(
                  price.calculated_base_prices.original_price_min,
                ) +
                " - " +
                priceInCurrency(
                  price.calculated_base_prices.original_price_max,
                ) +
                '</div><div class="text-xs">' +
                useNuxtApp().$i18n.t("pricelist_1_price") +
                '</div><div class="font-bold">' +
                priceInCurrency(price.calculated_pricelist_1_prices.price_min) +
                " - " +
                priceInCurrency(price.calculated_pricelist_1_prices.price_max) +
                "</div>"
              );
            }
          }
        }
      }
    }

    // 1.b, a felhasználó vendég, vagy nem TV

    // 1.b.i
    if (
      price.calculated_base_prices.original_price_min ===
      price.calculated_base_prices.price_min
    ) {
      // 1.b.i.1, a termék nem akciós

      // 1.b.i.1.a
      if (
        price.calculated_base_prices.original_price_min ===
        price.calculated_base_prices.original_price_max
      ) {
        // 1.b.i.1.a.i, tehát minden variánsnak egy ára van
        return (
          '<!-- 1.b.i.1.a.i --><div class="font-bold">' +
          priceInCurrency(price.calculated_base_prices.original_price_min) +
          "</div>"
        );
      } else {
        // 1.b.i.1.a.ii, tehát vannak különböző árú variánsok
        return (
          '<!-- 1.b.i.1.a.ii --><div class="font-bold">' +
          priceInCurrency(price.calculated_base_prices.original_price_min) +
          " - " +
          priceInCurrency(price.calculated_base_prices.original_price_max) +
          "</div>"
        );
      }
    } else {
      // 1.b.i.2

      // 1.b.i.2.a
      // eslint-disable-next-line no-lonely-if
      if (
        price.calculated_base_prices.original_price_min ===
        price.calculated_base_prices.original_price_max
      ) {
        // 1.b.i.2.a.ii

        // 1.b.i.2.a.ii.1
        if (
          price.calculated_base_prices.price_min ===
          price.calculated_base_prices.price_max
        ) {
          // 1.b.i.2.a.ii.1.a, tehát minden variánsnak egy ára van
          return (
            '<!-- 1.b.i.2.a.ii.1.a --><div class="originalPrice whitespace-nowrap">' +
            priceInCurrency(price.calculated_base_prices.original_price_min) +
            '</div><div class="inActionPrice font-bold">' +
            priceInCurrency(price.calculated_base_prices.price_min) +
            "</div>"
          );
        } else {
          // 1.b.i.2.a.ii.1.b, tehát vannak különböző árú variánsok
          return (
            '<!-- 1.b.i.2.a.ii.1.b --><div class="originalPrice whitespace-nowrap">' +
            priceInCurrency(price.calculated_base_prices.original_price_min) +
            '</div><div class="inActionPrice font-bold">' +
            priceInCurrency(price.calculated_base_prices.price_min) +
            " - " +
            priceInCurrency(price.calculated_base_prices.price_max) +
            "</div>"
          );
        }
      } else {
        // 1.b.i.2.a.i

        // 1.b.i.2.a.i.1
        // eslint-disable-next-line no-lonely-if
        if (
          price.calculated_base_prices.price_min ===
          price.calculated_base_prices.price_max
        ) {
          // 1.b.i.2.a.i.1.a, tehát minden variánsnak egy ára van
          return (
            '<!-- 1.b.i.2.a.i.1.a --><div class="originalPrice whitespace-nowrap">' +
            priceInCurrency(price.calculated_base_prices.original_price_min) +
            " - " +
            priceInCurrency(price.calculated_base_prices.original_price_max) +
            '</div><div class="inActionPrice font-bold">' +
            priceInCurrency(price.calculated_base_prices.price_min) +
            "</div>"
          );
        } else {
          // 1.b.i.2.a.i.1.b, tehát vannak különböző árú variánsok
          return (
            '<!-- 1.b.i.2.a.i.1.b --><div class="originalPrice whitespace-nowrap">' +
            priceInCurrency(price.calculated_base_prices.original_price_min) +
            " - " +
            priceInCurrency(price.calculated_base_prices.original_price_max) +
            '</div><div class="inActionPrice font-bold">' +
            priceInCurrency(price.calculated_base_prices.price_min) +
            " -" +
            priceInCurrency(price.calculated_base_prices.price_max) +
            "</div>"
          );
        }
      }
    }
  } else if (method === "product") {
    // return JSON.stringify(price);
    const { status, data } = useAuth();
    // console.log(price);

    if (status.value === "authenticated") {
      if (
        Array.isArray(data?.value?.usergroups) &&
        data?.value?.usergroups?.includes(1)
      ) {
        if (price.price === price.original_price) {
          return (
            '<div class="originalPrice whitespace-nowrap">' +
            priceInCurrency(price.original_price) +
            '</div><div class="text-xs">' +
            useNuxtApp().$i18n.t("pricelist_1_price") +
            '</div><div class="font-bold">' +
            priceInCurrency(price.pricelist_price) +
            "</div>"
          );
        } else {
          // eslint-disable-next-line no-lonely-if
          if (price.price === price.pricelist_price) {
            return (
              '<div class="originalPrice whitespace-nowrap">' +
              priceInCurrency(price.original_price) +
              '</div><div class="inActionPrice font-bold">' +
              priceInCurrency(price.price) +
              "</div>"
            );
          } else {
            return (
              '<div class="originalPrice whitespace-nowrap">' +
              priceInCurrency(price.original_price) +
              '</div><div class="text-xs">' +
              useNuxtApp().$i18n.t("pricelist_1_price") +
              '</div><div class="font-bold">' +
              priceInCurrency(price.pricelist_price) +
              "</div>"
            );
          }
        }
      }
    }

    // Vendég, vagy belépett de nem TV
    if (price.original_price === price.price) {
      return (
        '<div class="font-bold">' + priceInCurrency(price.price) + "</div>"
      );
    } else {
      return (
        '<div class="originalPrice whitespace-nowrap">' +
        priceInCurrency(price.original_price) +
        '</div><div class="inActionPrice font-bold">' +
        priceInCurrency(price.price) +
        "</div>"
      );
    }
  }
}
