<script lang="ts" setup>
// -----------------------
// props & emits
// -----------------------
const props = withDefaults(
  defineProps<{
    totalItems: number;
    visibleItems: number;
    title?: string;
    scrollArrowSize?: number;
    scrollArrowSpacing?: number;
    infinite?: boolean;
  }>(),
  {
    title: "",
    scrollArrowSize: 40,
    scrollArrowSpacing: 50,
    infinite: false,
  },
);

// -----------------------
// reactive properties
// -----------------------
const scrollIndex = ref(0);
const slidesContainer = ref(null);
const lastScrollFn = ref(null);
const scrollEl = ref(null);
const scrollElMarginLeft = ref(null);
const scrollDirection = ref<"left" | "right" | null>(null);
const transitionDuration = ref(300);

// -----------------------
// computed properties
// -----------------------
const canScroll = computed(() => {
  return props.totalItems > props.visibleItems;
});

const canScrollLeft = computed(() => {
  if (props.infinite) {
    return true;
  }

  return scrollIndex.value > 0;
});

const canScrollRight = computed(() => {
  if (props.infinite) {
    return true;
  }

  return scrollIndex.value < props.totalItems - props.visibleItems;
});

const buttonStyle = computed(() => {
  return {
    width: `${props.scrollArrowSize}px`,
    height: `${props.scrollArrowSize}px`,
    "font-size": `${props.scrollArrowSize}px`,
  };
});

const leftButtonStyle = computed(() => {
  return {
    ...buttonStyle.value,
    "margin-right": `${props.scrollArrowSpacing}px`,
  };
});

const rightButtonStyle = computed(() => {
  return {
    ...buttonStyle.value,
    "margin-left": `${props.scrollArrowSpacing}px`,
  };
});

// -----------------------
// helper methods
// -----------------------
const performScroll = () => {
  if (scrollDirection.value === "right") {
    scrollEl.value = slidesContainer.value.firstElementChild;
  } else {
    scrollEl.value = slidesContainer.value.lastElementChild;
  }

  const style =
    scrollEl.value.currentStyle || window.getComputedStyle(scrollEl.value);
  const marginX = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
  const borderX =
    parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
  const totalWidth = scrollEl.value.offsetWidth + marginX + borderX;
  scrollElMarginLeft.value = parseFloat(style.marginLeft);

  if (scrollDirection.value === "right") {
    scrollEl.value.classList.add(
      "transition-all",
      `duration-${transitionDuration.value}`,
    );
    scrollEl.value.style.marginLeft = `-${totalWidth - scrollElMarginLeft.value}px`;
  } else {
    scrollEl.value.style.marginLeft = `-${totalWidth - scrollElMarginLeft.value}px`;
    scrollEl.value.classList.add(
      "transition-all",
      `duration-${transitionDuration.value}`,
    );
    slidesContainer.value.removeChild(scrollEl.value);
    slidesContainer.value.prepend(scrollEl.value);

    window.requestAnimationFrame(() => {
      scrollEl.value.style.marginLeft = `${scrollElMarginLeft.value}px`;
    });
  }

  lastScrollFn.value = setTimeout(() => {
    if (scrollDirection.value === "right") {
      slidesContainer.value.removeChild(scrollEl.value);
      scrollEl.value.style.marginLeft = `${scrollElMarginLeft.value}px`;
    }

    scrollEl.value.classList.remove(
      "transition-all",
      `duration-${transitionDuration.value}`,
    );

    if (scrollDirection.value === "right") {
      slidesContainer.value.appendChild(scrollEl.value);
    }

    lastScrollFn.value = null;
    scrollEl.value = null;
  }, transitionDuration.value);
};

const finishPreviousScroll = () => {
  clearTimeout(lastScrollFn.value);
  lastScrollFn.value = null;

  scrollEl.value.classList.remove(`duration-${transitionDuration.value}`);
  scrollEl.value.classList.add("duration-100");

  setTimeout(() => {
    if (scrollDirection.value === "right") {
      slidesContainer.value.removeChild(scrollEl.value);
    }

    scrollEl.value.style.marginLeft = `${scrollElMarginLeft.value}px`;
    scrollEl.value.classList.remove("transition-all", "duration-100");

    if (scrollDirection.value === "right") {
      slidesContainer.value.appendChild(scrollEl.value);
    }

    performScroll();
  }, 100);
};

const scrollLeft = () => {
  if (canScrollLeft.value) {
    scrollIndex.value--;
    scrollDirection.value = "left";

    if (lastScrollFn.value !== null) {
      finishPreviousScroll();
    } else {
      performScroll();
    }
  }
};

const scrollRight = () => {
  if (canScrollRight.value) {
    scrollIndex.value++;
    scrollDirection.value = "right";

    if (lastScrollFn.value !== null) {
      finishPreviousScroll();
    } else {
      performScroll();
    }
  }
};
</script>

<template>
  <div>
    <h2
      v-if="title && title.length > 0"
      class="block font-lora font-bold !text-30 text-center lg:mb-[50px]"
    >
      {{ title }}
    </h2>
    <div class="flex flex-col md:flex-row items-center">
      <!-- Desktop button -->
      <button
        v-if="canScroll"
        :aria-label="$t('previous')"
        class="hidden md:block"
        :style="leftButtonStyle"
        :disabled="!canScrollLeft"
        :class="{ 'opacity-30': !canScrollLeft }"
        @click="scrollLeft"
      >
        <i class="m-icon-arrow_left"></i>
      </button>

      <div
        ref="slidesContainer"
        v-touch:swipe.left="scrollRight"
        v-touch:swipe.right="scrollLeft"
        class="w-full pl-[6px] pr-[6px] pb-[6px] flex justify-start items-stretch overflow-hidden"
      >
        <slot name="default"></slot>
      </div>

      <!-- Desktop button -->
      <button
        v-if="canScroll"
        :aria-label="$t('next')"
        class="hidden md:block"
        :disabled="!canScrollRight"
        :style="rightButtonStyle"
        :class="{ 'opacity-30': !canScrollRight }"
        @click="scrollRight"
      >
        <i class="m-icon-arrow_right"></i>
      </button>

      <!-- Mobile buttons -->
      <div
        class="flex md:hidden justify-between w-full"
        :style="{ padding: `20px ${scrollArrowSpacing}px 12px` }"
      >
        <button
          v-if="canScroll"
          :aria-label="$t('previous')"
          :style="leftButtonStyle"
          :disabled="!canScrollLeft"
          :class="{ 'opacity-30': !canScrollLeft }"
          @click="scrollLeft"
        >
          <i class="m-icon-arrow_left"></i>
        </button>
        <button
          v-if="canScroll"
          :aria-label="$t('next')"
          :disabled="!canScrollRight"
          :style="rightButtonStyle"
          :class="{ 'opacity-30': !canScrollRight }"
          @click="scrollRight"
        >
          <i class="m-icon-arrow_right"></i>
        </button>
      </div>
    </div>
  </div>
</template>
