import { filename } from "pathe/utils";
import { MasterProduct, Product } from "~/types/common";

export function useHelpers() {
  const totalStockOfVariant = (variant: Product): number => {
    return variant.product.product_keszlet_property.reduce((total, store) => {
      const stock = store && "value" in store ? store.value : 0;
      // const stock = store && "value" in store && "key" in store && store.key !== "miskolc" ? store.value : 0; // MNTX-688

      return total + stock;
    }, 0);
  };

  const getVariantByProperty = (
    masterProduct: MasterProduct,
    property: string,
    value: any,
  ): Product | undefined => {
    return masterProduct.products.find((variant: Product) => {
      if (property in variant.product) {
        return variant.product[property] === value;
      } else {
        return false;
      }
    });
  };

  const isIconFont = (src: any): boolean => {
    if (typeof src === "string" && src.length > 0) {
      return !isIconImage(src);
    }

    return false;
  };

  const isIconImage = (src: any): boolean => {
    if (typeof src === "string" && src.length > 0) {
      return /\.(svg|png|jpg|jpeg|webp|gif|apng|avif)$/.test(src);
    }

    return false;
  };

  const productPageUrl = (
    product: Record<string, any> | null,
    relative: boolean = true,
  ): string => {
    if (product) {
      let base = "/";
      if (!relative) {
        const url = useRequestURL();
        base = url.protocol + "//" + url.host + "/";
      }
      const config = useRuntimeConfig();
      const name = product.url_code;
      return encodeURI(
        `${base}${config.public.routes.product}/${product.sku}/${name}`,
      );
    }
    return "";
  };

  const useAssets = () => {
    const config = useRuntimeConfig();
    // this is ugly, but import.meta.blog can only take strings, not literals.
    if (config.public.theme.site === "tnf") {
      const glob = import.meta.glob(`@/assets/theme/tnf/*.png`, {
        eager: true,
      });
      const themePngs = Object.fromEntries(
        Object.entries(glob).map(([key, value]) => [
          filename(key),
          value.default,
        ]),
      );
      return { themePngs };
    }
  };

  // the wysiwyg in admin saves "<p><br></p>" for empty content, we don't want to display such non-real content
  const isRealContent = (content: string | null | undefined) => {
    const stripped = content?.replace(/<[^>]*>/g, "").trim();
    if (stripped) return stripped.length > 0;
    return false;
  };

  const createMetaDescription = (htmlString: string | undefined) => {
    // Step 1: Strip HTML tags using a temporary DOM element
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = String(htmlString);
    const textContent = tempDiv.textContent || tempDiv.innerText || "";

    // Step 2: Truncate the string to 150 characters
    const maxLength = 150;
    let truncatedString = textContent.trim();

    if (truncatedString.length > maxLength) {
      truncatedString = truncatedString.substring(0, maxLength) + "...";
    }

    return truncatedString;
  };

  const getValueByKeyFromKeyValueData = (
    kvData: Record<string, any>[] | undefined | null,
    key: string,
  ) => {
    if (!Array.isArray(kvData)) return null;
    const foundItem = kvData.find(
      (item: Record<string, any>) => item.key === key,
    );
    return foundItem ? foundItem.value : null;
  };

  const logoutUser = async () => {
    const { signOut /*, status: authStatus ,data: authData */ } = useAuth();
    const checkoutStore = useCheckoutStore();
    const cartStore = useCartStore();
    const { $api } = useNuxtApp();

    // console.log("signing out from logoutUser()");

    checkoutStore.reset();
    localStorage.setItem("webshop.cart.code", "");

    await $api("user/frontend-logout").catch((/* err */) => {
      // if the personal access token in API is expired / deleted then we can get 401 for this, so need to catch it to avoid "unhandled" error
      // console.error(err.message);
    });
    cartStore.fetchCartData();
    signOut();
  };

  const bucketBaseUrl = () => {
    const config = useRuntimeConfig();

    if (String(config.public.bucketName).includes(".")) {
      // if it includes a dot then it should be a full domain throught CDN so we use that for shorter URL's, see: https://docs.wasabi.com/docs/how-do-i-use-cloudflare-with-wasabi
      if (String(config.public.bucketName).endsWith("/")) {
        return String(config.public.bucketName);
      }
      return String(config.public.bucketName) + "/";
    } else {
      // otherwise just a normal bucket without a CDN domain
      return `https://s3.eu-central-2.wasabisys.com/${config.public.bucketName}/`;
    }
  };

  const getAssetUrl = (originalUrl: string) => {
    if (!originalUrl.includes("Public/assets")) {
      return originalUrl;
    }

    const relativeUrl = originalUrl.replace(/^.*Public\/assets(.*)$/, "$1");

    return `${bucketBaseUrl()}Public/assets${relativeUrl}`;
  };

  const getYoutubeVideoId = (url?: string | null): string => {
    if (!url) {
      return "";
    }

    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return "";
    }
  };

  const stringToHash = (string: string) => {
    let hash = 0;
    let i;
    let char;

    if (string.length === 0) return hash;

    for (i = 0; i < string.length; i++) {
      char = string.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }

    return hash;
  };

  return {
    bucketBaseUrl,
    getAssetUrl,
    totalStockOfVariant,
    getVariantByProperty,
    isIconFont,
    isIconImage,
    productPageUrl,
    useAssets,
    isRealContent,
    createMetaDescription,
    getValueByKeyFromKeyValueData,
    logoutUser,
    getYoutubeVideoId,
    stringToHash,
  };
}
