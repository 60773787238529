<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    type?: string;
    icon?: string;
    disabled?: boolean;
    shadowInside?: boolean;
    shadowOutside?: boolean;
    fontWeight?: string;
  }>(),
  {
    type: "primary",
    disabled: false,
    shadowInside: false,
    shadowOutside: false,
    icon: "",
    fontWeight: "semibold",
  },
);

const computedClasses = computed(() => {
  const classes: string[] = [`font-${props.fontWeight}`];

  if (props.disabled) {
    classes.push("cursor-not-allowed");

    if (props.type === "plain") {
      classes.push("bg-white");
      classes.push("text-[#b2b2b2]");
      classes.push("rounded-10");
    } else if (props.type === "footer") {
      classes.push("rounded-30");
      classes.push("h-[44px]");
      classes.push("text-slate-600");
    } else {
      classes.push("bg-brand-primary");
      classes.push("text-white");
      classes.push("rounded-50");
    }
  } else {
    if (props.type === "primary") {
      classes.push("bg-brand-primary");
      classes.push("text-white");
      classes.push("rounded-50");
    }

    if (props.type === "secondary") {
      classes.push("bg-white");
      classes.push("border");
      classes.push("border-black");
      classes.push("text-black");
      classes.push("rounded-50");
    }

    if (props.type === "cms-component") {
      classes.push("bg-brand-headline");
      classes.push("text-white");
      classes.push("rounded-50");
    }

    if (props.type === "checkout") {
      classes.push("bg-brand-search");
      classes.push("text-white");
      classes.push("rounded-50");
    }

    if (props.type === "green") {
      classes.push("bg-brand-primary");
      classes.push("text-white");
      classes.push("rounded-50");
    }

    if (props.type === "plain") {
      classes.push("bg-white");
      classes.push("rounded-10");
    }

    if (props.type === "footer") {
      classes.push("bg-brand-secondary");
      classes.push("rounded-30");
      classes.push("h-[44px]");
    }
  }

  if (props.shadowInside) {
    classes.push("shadow-inside");
  }

  if (props.shadowOutside) {
    classes.push("shadow-outside");
  }

  return classes.join(" ");
});
</script>

<template>
  <button
    :disabled="props.disabled"
    class="min-h-[34px] md:min-h-[44px] px-[20px]"
    :class="computedClasses"
  >
    <NuxtImg
      v-if="props.icon"
      :src="props.icon"
      class="mr-[10px]"
      format="webp"
      loading="lazy"
    />
    <slot name="default"></slot>
  </button>
</template>
<style scoped>
button img {
  display: inline-block;
}

.shadow-inside {
  box-shadow: inset -4px -4px 4px 0 rgba(0, 0, 0, 0.25);
}

.shadow-outside {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.shadow-inside.shadow-outside {
  box-shadow:
    inset -4px -4px 4px 0 rgba(0, 0, 0, 0.25),
    0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
</style>
